import * as React from 'react'
import {
  media,
  HSpaced,
  Body,
  BodyLarge,
  H3Alternate,
  FontWeight,
  Block
} from '@thesisedu/web/dist'
import { ArrowRightOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Container, HoverBrochureCardSmall, LargeH1, Subtitle } from '../components/styled'
import { SupportWidget } from '../components/support/SupportWidget'
import { SEO } from '../components/SEO'

import contactUs from '../images/learn/contact-us.jpg'
import knowledgebase from '../images/learn/knowledgebase.jpg'
import demo from '../images/learn/demo.jpg'

const LearnContent: React.FC = () => {
  return (
    <>
      <Container>
        <SEO title={'Learn'} />
        <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
          <LargeH1 isBlock={false}>Learn Prodigies Academy</LargeH1>
          <Block marginTop={'@size-xs'}>
            <Subtitle>Plenty of ways to learn, one great platform.</Subtitle>
          </Block>
        </Block>
        <LearnBlocksContainer>
          <LearnBlock
            title={'Schedule a demo to see Prodigies Academy in action.'}
            image={demo}
            linkText={'Schedule a Demo'}
            href={'mailto:sales@prodigiesacademy.com'}
          >
            Not convinced yet? Setup a demo with our sales team to see what Prodigies Academy can do
            for you.
          </LearnBlock>
          <LearnBlock
            title={'Visit our knowledge base for quick answers.'}
            image={knowledgebase}
            linkText={'Visit our Knowledge Base'}
            href={'/learn/articles'}
          >
            Our knowledge base contains articles on everything from getting started to in-depth
            guides.
          </LearnBlock>
          <LearnBlock
            title={'Contact us for additional support, any time.'}
            image={contactUs}
            linkText={'Contact Support'}
            href={'#support'}
          >
            Call, email, or chat our support team, any time you need help.
          </LearnBlock>
        </LearnBlocksContainer>
        <Block marginTop={'@size-section-md'} id={'support'}>
          <SupportWidget />
        </Block>
      </Container>
    </>
  )
}

const LearnBlocksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${(props) => props.theme['@size-s']};
  align-items: stretch;
  justify-content: flex-start;
  > * {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 0 ${(props) => props.theme['@size-s']} ${(props) => props.theme['@size-l']}
      ${(props) => props.theme['@size-s']};
    width: 100%;
    ${media.md} {
      width: 50%;
    }
    ${media.lg} {
      width: calc(33% + ${(props) => props.theme['@size-s']} * (1 / 5));
    }
  }
`

interface LearnBlockProps {
  title: string
  image: any
  linkText: string
  href?: string
  onClick?: () => void
}
function LearnBlock({
  title,
  image,
  linkText,
  href,
  onClick,
  children
}: React.PropsWithChildren<LearnBlockProps>) {
  const content = (
    <LearnBlockContainer onClick={onClick}>
      <img className={'image'} src={image} alt={title} />
      <div className={'content'}>
        <H3Alternate weight={FontWeight.SemiBold}>{title}</H3Alternate>
        <Block marginTop={'@size-xs'} marginBottom={'@size-s'}>
          <BodyLarge>{children}</BodyLarge>
        </Block>
        <Body color={'@primary-color'}>
          <HSpaced space={'@size-xs'}>
            <span>{linkText}</span>
            <ArrowRightOutlined />
          </HSpaced>
        </Body>
      </div>
    </LearnBlockContainer>
  )

  return href?.includes(':') ? (
    <a href={href}>{content}</a>
  ) : href ? (
    <AnchorLink to={href}>{content}</AnchorLink>
  ) : (
    <div>{content}</div>
  )
}

const LearnBlockContainer = styled(HoverBrochureCardSmall)`
  color: ${(props) => props.theme['@text-color']};
  > .image {
    border-top-left-radius: ${(props) => props.theme['@border-radius-large']};
    border-top-right-radius: ${(props) => props.theme['@border-radius-large']};
    overflow: hidden;
    width: 100%;
  }
  > .content {
    padding: ${(props) => props.theme['@size-m']};
  }
`

const LearnPage: React.FC = () => <LearnContent />

export default LearnPage
